import { BodyContainer, CustomPopup, Des } from './style.css'
import { AspectRatio, ELanguage, Utils } from '@microfrontends/react-components'
import React, { useEffect, useState } from 'react'
import Image from 'next/legacy/image'
import { getPopupBanner } from '../apiClient'

type TProps = {
	langCode: ELanguage
	primaryBtnText: string
}

type TBanner = {
	title: string
	description: string
	url: string
	image: string
	repeatType: number
	numberOfRepeat: number
}

enum ERepeatType {
	ALWAYS = 1,
	WEEK = 2
}

const PopupBanner = (props: TProps) => {
	const { langCode, primaryBtnText = '' } = props
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false)
	const [banner, setBanner] = useState<TBanner>()

	const handleShowPopupBanner = (banner: TBanner) => {
		if (banner === undefined) {
			return
		}

		if (banner?.repeatType === ERepeatType.ALWAYS) {
			setOpenPopup(true)
		}

		if (banner?.repeatType === ERepeatType.WEEK) {
			const numOfRepeatBanner =
				Utils.getCookie('POPUP_BANNER_REPEAT') || 0
			const isViewToday = Utils.getCookie('IS_VIEW_BANNER_TODAY') || false
			if (
				banner.numberOfRepeat > Number(numOfRepeatBanner) &&
				!isViewToday
			) {
				setOpenPopup(true)
				Utils.setCookie('IS_VIEW_BANNER_TODAY', 'true', 1)
				Utils.setCookie(
					'POPUP_BANNER_REPEAT',
					(Number(numOfRepeatBanner) + 1).toString(),
					7
				)
			}
		}
	}

	useEffect(() => {
		const getBanners = async () => {
			const bannersList = await getPopupBanner(langCode)
			if (bannersList.banners.length > 0) {
				setBanner(bannersList.banners[0])
				handleShowPopupBanner(bannersList.banners[0])
			}
		}
		// ToDo get popup banner
		getBanners()
	}, [langCode])

	return (
		<CustomPopup
			isShow={isOpenPopup}
			lang={langCode ?? ELanguage.VI}
			onClickOutside={() => {
				setOpenPopup(false)
			}}
			text={{
				header: banner?.title,
				primaryBtn: primaryBtnText
			}}
			onClickPrimary={() => {
				window.location.href = banner?.url || '#'
			}}
			onClose={() => {
				setOpenPopup(false)
			}}
			option={{
				isShowSecondaryButton: false,
				isCenterFooter: true
			}}
		>
			<BodyContainer>
				<AspectRatio ratio={16 / 9}>
					{banner?.image !== '' ? (
						<Image
							style={{ borderRadius: 8, marginBottom: 16 }}
							src={banner?.image || ''}
							layout='fill'
							alt={banner?.title || ''}
						/>
					) : null}
				</AspectRatio>
				<Des>{banner?.description}</Des>
			</BodyContainer>
		</CustomPopup>
	)
}

export default PopupBanner
