export const translation: Record<string, any> = {
	English: 'Tiếng Việt',
	'Search for jobs, companies, skills': 'Tìm kiếm việc làm, công ty, kỹ năng',
	Search: 'Tìm kiếm',
	jobs: 'Việc Làm',
	Job: 'Việc làm',
	'Recent Searches': 'Tìm kiếm gần đây',
	'Clear all': 'Xóa tất cả',
	'Hot Keywords': 'Từ khóa phổ biến',
	'My Career Center': 'Quản Lý Nghề Nghiệp',
	'/job-search/all-jobs': '/tim-viec-lam/tim-tat-ca-viec-lam',
	'All Jobs': 'Tất Cả Việc Làm',
	'/job-channel/top-management': '/kenh-viec-lam/quan-ly',
	'Management Jobs': 'Việc Làm Cấp Quản Lý',
	New: 'Mới',
	'/wow-cv': '/ho-so-chuyen-nghiep',
	Employer: 'Nhà tuyển dụng',
	'Your Move-Up': 'Thăng Tiến',
	Dashboard: 'Quản Lý Hồ Sơ',
	'Saved Jobs': 'Việc Làm Của Tôi',
	'/my-career-center/my-job-alert': '/quan-ly-nghe-nghiep/thong-bao-viec-lam',
	'Job Alerts': 'Thông Báo Việc Làm',
	'/my-career-center/who-view-my-resume':
		'/quan-ly-nghe-nghiep/nha-tuyen-dung-nao-xem-ho-so-cua-ban',
	'Who Viewed My Profile': 'Nhà Tuyển Dụng Xem Hồ Sơ',
	'/my-career-center/my-account': '/quan-ly-nghe-nghiep/tai-khoan',
	'Account Settings': 'Tài Khoản',
	'Log out': 'Thoát',
	'Trending Jobs': 'Xu Hướng Việc Làm',
	'Recommendation Jobs': 'Việc Làm Gợi Ý',
	'Featured Companies': 'Các Công Ty Hàng Đầu',
	'Hot Categories': 'Ngành Nghề Trọng Điểm',
	'Featured Jobs': 'Việc Làm Tốt Nhất',
	'/featured-jobs': '/viec-lam-tot-nhat',
	'Top Categories': 'Ngành Nghề Nổi Bật',
	'Company Spotlight': 'Nhà Tuyển Dụng Nổi Bật',
	'View more': 'Xem thêm',
	'VietnamWorks - Recruitment, employment, salary lookup & career advice platform':
		'Nền tảng Việc làm chất lượng & Tuyển dụng hiệu quả, nhanh chóng - VietnamWorks',
	'Search for recruitment information - jobs - look up salary & interview experience at the website of the largest recruitment platform in Vietnam. Update new work every day. Find out now at VietnamWorks!':
		'Nền tảng tuyển dụng lớn nhất tại Việt Nam với hơn 10.000 cơ hội việc làm mới nhất, chất lượng. Tiếp cận 10,000+ doanh nghiệp tuyển dụng uy tín. Tra cứu lương, thưởng, tư vấn nghề nghiệp tại VietnamWorks.',
	'Job Search, Recruitment': 'tuyển dụng việc làm, tìm việc làm nhanh',
	'/en': '/vi',
	'/company/vietnamworks?lang=en': '/company/vietnamworks',
	'About Us': 'Về VietnamWorks',
	'/contact-us': '/lien-he',
	'Contact Us': 'Liên Hệ',
	'/press-corner': '/goc-bao-chi',
	'Press Corner': 'Góc Báo Chí',
	FAQ: 'Hỏi Đáp',
	'/operation-ecommerce': '/quy-che-hoat-dong-thuong-mai-dien-tu',
	'Operation Regulation Of VietnamWorks E-Commerce Trading Floor':
		'Quy Chế Hoạt Động Sàn Giao Dịch Thương Mại Điện Tử VietnamWorks',
	'Mobile Apps': 'Ứng Dụng Di Động',
	'Jobs by location': 'Việc làm theo khu vực',
	'Search job by category': 'Tìm việc làm theo ngành nghề',
	'Search job by city': 'Tìm việc làm theo khu vực',
	'Jobs by category': 'Việc làm theo ngành nghề',
	'Jobs by categories': 'Việc làm theo ngành nghề',
	'/job-search-location': '/tim-viec-lam-theo-khu-vuc',
	'/job-search': '/tim-viec-lam',
	'See all locations': 'Xem tất cả khu vực',
	'View all cities': 'Xem tất cả khu vực',
	'See all categories': 'Xem tất cả ngành nghề',
	'View all categories': 'Xem tất cả ngành nghề',
	'Mobile app': 'Ứng dụng di động',
	'Verified by': 'Chứng nhận bởi',
	'Copyright © Navigos Group Vietnam Joint Stock Company':
		'Copyright © Công Ty Cổ Phần Navigos Group Việt Nam',
	'Floor 20, E.Town Central, 11 Doan Van Bo Street, Ward 12 District 4, Ho Chi Minh City, Vietnam':
		'Tầng 20, tòa nhà E.Town Central, 11 Đoàn Văn Bơ, Phường 12, Quận 4, TP.HCM, Việt Nam',
	'/terms-of-use': '/thoa-thuan-su-dung',
	Terms: 'Thỏa thuận sử dụng',
	'Terms Of Use': 'Thỏa Thuận Sử Dụng',
	'/privacy-policy': '/quy-dinh-bao-mat',
	Privacy: 'Quy Định Bảo Mật',
	'Privacy Statement': 'Quy Định Bảo Mật',
	'/mobile': '/di-dong',
	'/site-map': '/so-do-trang-web',
	'Site Map': 'Sơ Đồ Trang Web',
	'/companies': '/cong-ty',
	Companies: 'Công Ty',
	Register: 'Đăng Ký',
	Login: 'Đăng Nhập',
	Logout: 'Thoát',
	'Hide my profile': 'Ẩn Hồ Sơ Của Tôi',
	Message: 'Thông điệp',
	Notifications: 'Thông báo',
	'Follow us on': 'Kết Nối Với VietnamWorks',
	day: 'ngày',
	days: 'ngày',
	'Expires in': 'Hết hạn trong',
	Expires: 'Hết hạn',
	Today: 'Hôm nay',
	today: 'hôm nay',
	'saved jobs': 'công việc bạn đã lưu',
	'days ago': 'ngày trước',
	'day ago': 'ngày trước',
	'viewed your profile': 'đã xem hồ sơ của bạn',
	'new jobs': 'công việc mới',
	'you might be interested based on your profile.':
		'hấp dẫn dành riêng cho bạn!',
	'Keep your profile updated to reach dream jobs easier':
		'Làm mới hồ sơ để tìm thấy công việc mơ ước dễ dàng hơn',
	'Update now': 'Cập nhật',
	'Sign in now to get your dream jobs':
		'Đăng nhập ngay để tìm thấy công việc mơ ước',
	'18 new jobs you might be interested based on your profile':
		'18 công việc mới hấp dẫn dành riêng cho bạn',
	'/my-profile': '/ho-so',
	'/recommended-jobs': '/viec-lam-goi-y',
	'Basic Profile Information': 'Thông tin cơ bản',
	'Upload CV': 'Tải hồ sơ',
	'Upload your CV': 'Tải hồ sơ',
	'Complete profile': 'Hoàn chỉnh hồ sơ',
	'Drag & Drop your CV file here': 'Kéo và thả tệp CV của bạn vào đây',
	'Browse files': 'Chọn hồ sơ từ máy bạn',
	'You can attach': 'Bạn có thể thêm tập tin có dạng',
	' and ': ' hoặc ',
	'that are less than': 'và dung lượng phải nhỏ hơn ',
	'Skip this step': 'Bỏ qua bước này',
	Next: 'Tiếp tục',
	'Your CV has been uploaded successfully!':
		'CV của bạn đã được tải lên thành công!',
	'Information is parsed and filled in for you automatically if possible':
		'Thông tin sẽ được phân tích và tự động điền vào hồ sơ của bạn nếu có thể.',
	'Your Job Title': 'Chức danh hiện tại',
	'This field is required': 'Đây là trường bắt buộc',
	'Please do not enter a number phone': 'Vui lòng không nhập số',
	'Please do not enter a email addess': 'Vui lòng không nhập địa chỉ email',
	'Your Current Job Level': 'Cấp bậc hiện tại',
	'Years of Experience': 'Số năm kinh nghiệm',
	years: 'năm',
	'Categories You Want To Get Jobs': 'Ngành nghề làm việc',
	'You can select a maximum number of 3 options':
		'Bạn có thể chọn tối đa 3 ngành nghề',
	'Places You Want To Work': 'Nơi làm việc mong muốn',
	'Highest Education': 'Trình độ học vấn',
	'Expected Minimum Salary': 'Mức lương mong muốn tối thiểu',
	'USD/month': 'USD/tháng',
	'← Back': '← Quay lại',
	'Save Profile': 'Lưu hồ sơ',
	"Here're jobs": 'Việc làm phù hợp',
	' matching with your profile': ' với hồ sơ của bạn',
	Accounting: 'Kế toán',
	'Select Job Level': 'Chọn cấp bậc',
	'Please choose': 'Vui lòng chọn',
	'No Options': 'Không tìm thấy kết quả nào',
	'No matches found': 'Không tìm thấy kết quả nào',
	Searching: 'Đang tìm kiếm',
	'Type to search': 'Nhập để tìm kiếm',
	'Make your profile searchable to increase your visibility to employers':
		'Cho phép nhà tuyển dụng tìm kiếm hồ sơ của bạn',
	'/job-channel/top-management/?utm_source_navi=vnw_header_menu&utm_medium_navi=TopManagementJobs&utm_term_navi=homepage2':
		'/kenh-viec-lam/quan-ly/?utm_source_navi=vnw_header_menu&utm_medium_navi=TopManagementJobs&utm_term_navi=homepage2',
	'https://learning.vietnamworks.com/?utm_source_navi=VNW.Site&utm_medium_navi=VNWUsers_Header&utm_term_navi=homepage2':
		'https://learning.vietnamworks.com/?utm_source_navi=VNW.Site&utm_medium_navi=VNWUsers_Header&utm_term_navi=homepage2',
	Language: 'Ngôn Ngữ',
	'HR Insider': 'Tư Vấn',
	'/wow-cv?utm_source_navi=vnw_header_menu&utm_medium_navi=wow_cv&utm_term_navi=homepage2':
		'/ho-so-chuyen-nghiep?utm_source=vnw_header_menu&utm_medium=wow_cv&utm_term=homepage2',
	Learning: 'Khóa Học',
	'/sign-up': '/dang-ky',
	'/my-career-center/employer-message':
		'/quan-ly-nghe-nghiep/phan-hoi-cua-nha-tuyen-dung',
	'No recents keyword': 'Không có từ khóa gần đây',
	'No Thanks': 'Không, cảm ơn',
	'Enjoy the Best Experience with our App':
		'Trải nghiệm tốt nhất với ứng dụng di động',
	'Find nearby jobs': 'Tìm việc gần bạn',
	'Receive new jobs': 'Nhận việc mới nhất',
	'Apply in seconds': 'Ứng tuyển nhanh',
	Download: 'Tải xuống',
	'Show me the jobs': 'Xem danh sách việc làm',
	'New jobs': 'Việc mới',
	'/my-career-center/dashboard': '/quan-ly-nghe-nghiep/quan-ly-ho-so',
	'/my-career-center/my-jobs': '/quan-ly-nghe-nghiep/viec-lam-cua-toi',
	Cancel: 'Hủy',
	'apple-download-image': '/static/images/apple-download.png',
	'android-download-image': '/static/images/android-download.png',
	'/job-channel/top-management/?utm_source=vnw_header_menu&utm_medium=TopManagementJobs&utm_term=homepage2':
		'/kenh-viec-lam/quan-ly/?utm_source=vnw_header_menu&utm_medium=TopManagementJobs&utm_term=homepage2',
	'/wow-cv?utm_source=vnw_header_menu&utm_medium=wow_cv&utm_term=homepage2':
		'/ho-so-chuyen-nghiep?utm_source=vnw_header_menu&utm_medium=wow_cv&utm_term=homepage2',
	'Are you searching for': 'Có phải bạn đang tìm',
	'Find your dream jobs': 'Tìm kiếm công việc mơ ước',
	'Chuyển sang Tiếng Việt': 'Switch to English',
	'Fun fact': 'Có thể bạn chưa biết',
	'According to VietnamWorks employees, using':
		'Theo nhân viên VietnamWorks, sử dụng',
	'helps increase chances to get a successful job up to':
		'tăng khả năng tìm được công việc mơ ước lên tới',
	'Another fact': 'Có thể bạn cũng chưa biết',
	'We have stopped supporting': 'Chúng tôi đã ngừng hỗ trợ',
	'and below': 'và các phiên bản trước',
	'For the best experience, please use':
		'Để có trải nghiệm tốt nhất, hãy sử dụng',
	or: 'hoặc',
	'Got it': 'Đồng ý',
	'https://www.google.com/chrome/': 'https://www.google.com/intl/vi/chrome/',
	'http://www.mozilla.com/firefox/upgrade.html':
		'https://www.mozilla.org/vi/firefox/new/',
	'Career Advice from HR Insider': 'Tư vấn nghề nghiệp từ HR Insider',
	'Career Advice from': 'Tư vấn nghề nghiệp từ',
	'Chuyển sang tiếng Việt': 'Switch to English',
	'Search all categories': 'Tìm theo khu vực',
	'Search all locations': 'Tìm theo ngành nghề',
	'employment/hiring with high salary & attractive benefits':
		'tuyển dụng - Tìm việc mới nhất, lương thưởng hấp dẫn.',
	'Best Jobs In': 'Việc Làm Nổi Bật Ngành',
	'View All': 'Xem Tất Cả',
	'Best Jobs': 'Việc Làm Nổi Bật',
	'All locations': 'Tất cả địa điểm',
	'No result': 'Không có kết quả',
	'You have selected up to three locations': 'Bạn đã chọn tối đa ba địa điểm',
	'Discover Now': 'Khám Phá Ngay',
	'Local Vietnamese': 'Người Việt Nam',
	Foreigner: 'Người nước ngoài',
	'View now': 'Xem ngay',
	'Protect yourself against VietnamWorks impersonation scams':
		'Hãy bảo vệ chính mình trước các trường hợp mạo danh VietnamWorks.',
	'Latest ': '',
	Numerology: 'Nhân Số Học',
	'A reference tool that helps you suggest the right career based on the strengths and weaknesses of the numbers that are right for you. Based on the information: Your name, date of birth, Numerology will analyze and create a complete career chart for you. Review to renew!':
		'Công cụ tham khảo nhằm giúp bạn gợi ý nghề nghiệp phù hợp dựa trên những điểm mạnh và điểm yếu của những con số liên quan đến bạn. Dựa trên thông tin: Họ tên, ngày tháng năm sinh của bạn, Nhân số học sẽ phân tích và tạo lập cho bạn biểu đồ sự nghiệp hoàn chỉnh.',
	'Career Path': 'Lộ Trình Sự Nghiệp:',
	"VietnamWorks helps you compare yourself to remove obstacles on the way to promotion. Let's get out of the career maze by consulting the career progression path of all professions!":
		'VietnamWorks giúp bạn đối chiếu được bản thân tháo gỡ lấn cấn chuyện lộ trình thăng tiến. Cùng thoát khỏi mê cung sự nghiệp bằng cách tham khảo lộ trình thăng tiến của tất cả các ngành nghề, bạn nhé!',
	numerology: 'nhan-so-hoc',
	'career-path': 'lo-trinh-su-nghiep',
	'News about VietnamWorks': 'Báo chí nói gì về VietnamWorks',
	'“Wow” your next employers with': 'Tạo ấn tượng với nhà tuyển dụng cùng',
	'Create yours CV now to win your dream jobs':
		'Tạo mẫu CV nhanh chóng và miễn phí, để nhà tuyển dụng phải',
	'/create-cv': '/tao-cv',
	'Create CV': 'Tạo CV ngay',
	'Jobseeker about Vietnamworks': 'Ứng viên nói về VietnamWorks'
}
