import { LANG_ID_EN } from './language'
import { TNestedGenericId } from '@microfrontends/react-components'

export function buildSearchByJobFunctionUrl(
	jobFunction: TNestedGenericId[],
	languageId: number
) {
	const params: Record<string, any> = {}
	if ((jobFunction || []).length > 0) {
		const groupJobFunctionId: number[] = []
		const jobFunctionId: number[] = []
		jobFunction.map((item) => {
			groupJobFunctionId.push(item.parentId)
			item.childrenIds.map(
				(child) => child !== -1 && jobFunctionId.push(child)
			)
		})
		if (groupJobFunctionId.length > 0) {
			params['g'] = groupJobFunctionId.join('.')
		}
		if (jobFunctionId.length > 0) {
			params['j'] = jobFunctionId.join('.')
		}
	}
	let url = languageId === LANG_ID_EN ? '/jobs' : '/viec-lam'
	if (Object.keys(params).length > 0) {
		const paramsString = '?' + new URLSearchParams(params).toString()
		url += paramsString
	}
	return buildVNWUrlFromPath(url)
}

export function buildVNWUrlFromPath(path: string) {
	return process.env.VNW_BASE_URL + path
}

export function assetUrl(path: string) {
	return process.env.APP_ASSET_URL + path
}

export function buildQueryString(params: any) {
	let queryString: any[] = []

	for (var key in params) {
		if (params.hasOwnProperty(key) && !!params[key]) {
			queryString.push(`${key}=${encodeURIComponent(params[key])}`)
		}
	}

	return queryString.length > 0 ? queryString.join('&') : ''
}

export function getUrlParameter(name: string) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
	let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
	let results = regex.exec(location.search)
	return results === null
		? ''
		: decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function buildSkillUrl(title: string, language: number, utm = '') {
	let currentUTMSource = getUrlParameter('utm_source_navi')
	currentUTMSource = !!currentUTMSource ? '/?source=searchResults' : utm
	return (
		'/' +
		encodeURIComponent(title) +
		'-' +
		(language == 2 ? 'kw' : 'kv') +
		currentUTMSource
	)
}
