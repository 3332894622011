import * as React from 'react'
const ShieldIcon = () => (
	<svg
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M10 2c-3.069 0-6.26.691-6.26.691l-.002.003A1.33 1.33 0 0 0 2.666 4v4c0 6.932 6.681 9.83 6.681 9.83a1.33 1.33 0 0 0 1.302 0h.003s6.68-2.898 6.68-9.83V4a1.334 1.334 0 0 0-1.073-1.309S13.067 2 9.999 2m4 4a.666.666 0 0 1 .471 1.138l-5.04 5.04a.666.666 0 0 1-.943 0L6.186 9.876a.666.666 0 1 1 .942-.942l1.831 1.83 4.569-4.569c.13-.13.3-.195.471-.195'
			fill='#fff'
		/>
	</svg>
)
export default ShieldIcon
