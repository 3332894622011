import { colors } from '@microfrontends/react-components'
import NextHead from 'next/head'
import { assetUrl } from '../helpers/urlBuilder'
import { useRouter } from 'next/router'

const favicon =
	`${process.env.VNW_BASE_URL}/favicon.ico?v=` + process.env.VNW_BUILD_ID

const defaultDescription = ''
const defaultOGURL = ''
const defaultOGImage = ''

type THeadProps = {
	keywords: string
	title: string
	description: string
	ogImage: string
	url: string
}

const Head = (props: THeadProps) => {
	const router = useRouter()
	const robots =
		router.asPath.indexOf('?') > 0 ? 'noindex, nofollow' : 'index, follow'

	return (
		<NextHead>
			<meta charSet='UTF-8' />
			<title>{props.title || ''}</title>
			<meta
				name='description'
				content={props.description || defaultDescription}
			/>
			<meta
				name='viewport'
				content='width=device-width, initial-scale=1'
			/>

			<meta name='theme-color' content={colors.primary[20]} />
			<meta name='robots' content={robots} />
			<meta name='keywords' content={props.keywords} />
			<meta property='og:url' content={props.url || defaultOGURL} />
			<meta property='og:title' content={props.title || ''} />
			<meta
				property='og:description'
				content={props.description || defaultDescription}
			/>
			<meta name='twitter:site' content={props.url || defaultOGURL} />
			<meta name='twitter:card' content='summary_large_image' />
			<meta
				name='twitter:image'
				content={props.ogImage || defaultOGImage}
			/>
			<meta
				property='og:image'
				content={props.ogImage || defaultOGImage}
			/>
			<meta
				property='og:image:secure_url'
				content={props.ogImage || defaultOGImage}
			/>
			<meta property='og:image:width' content='526' />
			<meta property='og:image:height' content='275' />

			<link rel='shortcut icon' href={favicon} type='image/x-icon' />
			<link rel='manifest' href={assetUrl('/static/manifest.json')} />
			<link rel='icon' href={favicon} />
			<link
				rel='apple-touch-icon'
				href={assetUrl('/static/images/vietnamwork_512.png')}
			/>
			<link rel='canonical' href='https://www.vietnamworks.com/' />

			{/* Use Resource Hints to reduce connection setup time */}
			{/* Link docs: https://web.dev/articles/optimizing-content-efficiency-loading-third-party-javascript?utm_source=lighthouse&utm_medium=lr#resource-hints */}
			{/* google */}
			<link rel='dns-prefetch' href='https://www.google.com' />
			<link rel='dns-prefetch' href='https://www.googletagmanager.com' />
			<link rel='dns-prefetch' href='https://www.gstatic.com' />
			<link rel='preconnect' href='https://maps.googleapis.com' />
			<link rel='preconnect' href='https://stats.g.doubleclick.net' />
			<link rel='preconnect' href='https://googleads.g.doubleclick.net' />
			{/* Facebook */}
			<link rel='dns-prefetch' href='https://www.facebook.com' />
			<link rel='preconnect' href='https://connect.facebook.net' />

			{/* https://spideraf.com/ */}
			<link rel='dns-prefetch' href='https://www.sp-trk.com' />
			{/* posthog */}
			<link rel='preconnect' href='https://us-assets.i.posthog.com' />
			<link rel='preconnect' href='https://us.i.posthog.com' />
			{/* tiktok */}
			<link rel='preconnect' href='https://analytics.tiktok.com' />
			{/* hotjar */}
			<link rel='preconnect' href='https://script.hotjar.com' />
			{/* netcoresmartech */}
			<link rel='preconnect' href='https://osjs.netcoresmartech.com' />
			<link rel='preconnect' href='https://cdnt.netcoresmartech.com' />
			<link
				rel='preconnect'
				href='https://psegment.netcoresmartech.com'
			/>
			<link rel='preconnect' href='https://twa.netcoresmartech.com' />
			{/* hansel.io */}
			<link rel='preconnect' href='https://cdn-sdk.hansel.io' />
			<link rel='preconnect' href='https://ujm.hansel.io' />
			{/*End use Resource Hints to reduce connection setup time */}
		</NextHead>
	)
}

export default Head
