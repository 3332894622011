/**
 * This event is called when user logged in successfully on LoginModal
 */
export const EVENT_UPDATE_USER = 'UPDATE_USER'

export const BLUR_DEFAULT_IMAGE =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkiPq/CQADyAINhiva0AAAAABJRU5ErkJggg=='

export const EVENT_DETECT_LOCAITON = 'detectLocationSuccess'

export const googleSchema = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	url: 'https://www.vietnamworks.com/',
	name: 'VietnamWorks',
	logo: 'https://images.vietnamworks.com/img/vietnamworks_256x256.png',
	address: {
		'@type': 'PostalAddress',
		addressLocality: 'Quận 4',
		addressRegion: 'Hồ Chí Minh',
		addressCountry: 'Việt Nam',
		postalCode: '700000',
		streetAddress: 'Tầng 20, E.Town Central, 11 Đoàn Văn Bơ, Phường 13'
	},
	contactPoint: [
		{
			'@type': 'ContactPoint',
			telephone: '+84 28 3925 8456',
			contactType: 'customer service'
		}
	],
	foundingDate: '2002',
	parentOrganization: 'Navigos Group Vietnam JSC',
	sameAs: [
		'https://www.facebook.com/VietnamWorksFanpage',
		'https://www.linkedin.com/company/vietnamworks',
		'https://www.crunchbase.com/organization/vietnamworks-com'
	]
}

export const GTM_ID = process.env.GOOGLE_TAG_MANAGER_ID
export const GA_ACCOUNT = process.env.GA_ACCOUNT
export const GA_DOMAIN = process.env.GA_DOMAIN
export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID =
	process.env.FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.FIREBASE_APP_ID

export const FIREBASE_MEASUREMENT_ID = process.env.FIREBASE_MEASUREMENT_ID
export const FIREBASE_VAPID_KEY = process.env.FIREBASE_VAPID_KEY
export const API_DOMAIN = process.env.API_DOMAIN
export const IP_TO_LOCATION_API_URL = process.env.IP_TO_LOCATION_API_URL
export const VNW_BUILD_ID = process.env.VNW_BUILD_ID
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY
export const VNW_BASE_URL = process.env.VNW_BASE_URL

export const smartechclientURL =
	'https://cdnt.netcoresmartech.com/smartechclient.js'
