export function encrypt(token: string): string {
	return btoa(btoa(token)).replace(/=/g, '')
}

export function decrypt(token: string): string {
	if (typeof atob != 'function') {
		return token
	}
	return atob(atob(token))
}
