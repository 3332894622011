import {
	ELanguage,
	TAuthUser,
	TNestedGenericId
} from '@microfrontends/react-components'
import React from 'react'

export type TAppContext = {
	serviceWorker: ServiceWorker | null
	user: TAuthUser | null
	userIp: string
	t: (str: string, replacement?: Record<string, any> | undefined) => string
	langCode: ELanguage
	locations: TNestedGenericId[]
}

const AppContext = React.createContext<TAppContext>({
	serviceWorker: null,
	user: null,
	userIp: '',
	t: () => '',
	langCode: ELanguage.VI,
	locations: []
})

export default AppContext
