export const LANG_CODE_EN = 'en'
export const LANG_CODE_VI = 'vi'
export const LANG_ID_EN = 2
export const LANG_ID_VI = 1
export const URL_SUFFIX_EN = 'en'
export const URL_SUFFIX_VI = 'vn'

export function getIdFromCode(code: string) {
	return code === LANG_CODE_EN ? LANG_ID_EN : LANG_ID_VI
}
