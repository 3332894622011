import React from 'react'
import { useIdentify, TAuthUser } from '@microfrontends/react-components'

const msBaseUrl = process.env.API_DOMAIN ?? ''

export interface IIdentifyUserProps {
	user: TAuthUser
}

const IdentifyUser = (props: IIdentifyUserProps) => {
	const { user } = props
	
	useIdentify(user, msBaseUrl)

	return <></>
}

export default IdentifyUser
