import styled from 'styled-components'
import { colors } from '@microfrontends/react-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: ${colors.primary[40]};
	padding: 8px 64px 8px 16px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	@media (max-width: 769px) {
		font-size: 12px;
		padding: 8px 48px 8px 16px;
	}
`

export const ImageWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
`

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const CloseBtn = styled.button`
	background: unset;
	border: unset;
	&:focus {
		outline: none;
	}
	position: absolute;
	right: 16px;
	@media (max-width: 769px) {
		right: 8px;
	}
`

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8px;
	margin-right: 16px;
	align-items: center;
	@media (max-width: 769px) {
		margin-right: 8px;
	}
`

export const IconWrapper = styled.div`
	@media (max-width: 769px) {
		margin-top: 1px;
	}
`

export const Link = styled.a`
	color: #82dcff;
	white-space: nowrap;
`
