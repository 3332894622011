import styled from 'styled-components'
import { Popup } from '@microfrontends/react-components'

export const CustomPopup = styled(Popup)`
	button.btn-primary {
		width: 100%;
		margin-left: 0;
	}
`
export const BodyContainer = styled.div`
	padding: 8px;
	border-radius: 8px;
`
export const Des = styled.div`
	display: -webkit-box;
	text-align: center;
	margin-top: 16px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`
